<template>
    <div class="col-xl-3 d-flex flex-column order-3">
        <div class="details__card gutter-10">
            <div class="details__card__head">
                <h4>{{ $t("Salary")}}</h4>
            </div>
            <div class="details__card__body">
                <ul class="info__list">
                    <li class="label__full  label__full--primary list-item--primary">
                        <p>{{ $t("Current salary")}}</p>
                        <p class="label__content">{{candidate.user.current_salary}}</p>
                    </li>
                    <li class="label__full  label__full--primary list-item--primary">
                        <p>{{ $t("Expected salary")}}</p>
                        <p class="label__content">{{candidate.expected_salary}}</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="details__card gutter-10">
            <div class="details__card__head" style="display: block">
                <h4>{{ $t("Notes")}}</h4>
                <div class="note__subtitle">
                    <i class="eicon e-question"></i> Only team members can see notes.
                </div>
            </div>
            <div class="details__card__body">
                <textarea wrap="hard" :class="{ 'has-error': error.length }" @keyup="draftNotes" v-model="note" name="" id="" rows="5" :placeholder="$t('Add a note here')" class="label__full--primary form-control"></textarea>
                <div class="error-message" v-text="error"></div>
                <multiselect
                        id="tags"
                        v-model="tags"
                        :options="companyManagers"
                        :placeholder="$t(`Tag a manager`)"
                        :multiple="true"
                        :taggable="false"
                        @input="draftNotes"
                        track-by="id"
                        label="name"
                ></multiselect>
                <div class="form-action mt-4">
                    <button type="button" class="button semi-button-danger" @click="resetNote()">{{ $t("Cancel")}}</button>
                    <submit-button :click="addNote" :type="'semi-button-info'" :loading="isLoading">{{ $t("Save")}}</submit-button>

                </div>
            </div>
        </div>
        <div class="details__card gutter-10 h-100" v-if="hasNotes">
            <div class="details__card__body">
                <div v-for="(note, index) in notes" class="note-info">
                    <a href="javascript:void(0);" v-if="note.show_delete" @click.prevent="deleteNote(index, note.id)" class="erase-button">
                        <i class="eicon e-delete"></i>
                    </a>
                    <p class="label__full--eraseble label__full--primary text-break">{{ note.note }}
                        <span class="tag-container" v-if="note.tags.length>0">
                            <div v-for="tag in note.tags" class="tag-name" :class="tag.my_tag?'highlight':''">@{{tag.name}}</div>
                        </span>
                    </p>
                    <div class="note-admin-info d-flex justify-content-between">
                        <div class="note-creator"><strong>{{ note.creator }}</strong></div>
                        <div class="note-time" v-html="dateConvertToSpan(note.created_at)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .label__full--eraseble {
        white-space: pre-line;
    }

    .page-body .content-area__body .details__card .note-info:not(:last-child) {
        margin-bottom: 20px;
    }
</style>
<script>
import client from "../../../app/api/Client";
import {mapState} from "vuex";
import {PERMISSIONS} from '../../../constants/enums';
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import { dateConvertSpan } from '../../../extra/helper';

export default {
        props : ['candidate'],
        components:{
            Multiselect
        },
        data() {
            return {
                error:'',
                note : "",
                notes : [],
                tags: [],
                managers:[],
                isLoading:false
            }

        },
        computed : {
            ...mapState(['company']),
            hasNotes() {
                return this.notes.length > 0;
            },
            companyManagers() {
              return this.managers.filter(m => m.name != '');
            },
        },
        mounted() {
                this.getNotes();
        },
        methods : {
            dateConvertToSpan(date) {
                return dateConvertSpan(date);
            },
            resetNote() {
                this.note = "";
                this.tags = [];
                localStorage.removeItem(this.key);
            },
            async getNotes(){
                try {
                    let {data: {data}} = await client().get(`job/applicant/${this.candidate.id}/note`);
                    this.notes = data.notes;
                    this.managers = data.managers;
                    this.note = data.draft_notes.note;
                    this.tags = data.draft_notes.tags;
                } catch (e) {

                }
            },
            async addNote(){
                if (this.company.permission[PERMISSIONS.CANDIDATE_VIEW]) {
                    if (this.note.length < 1) {
                        this.error = this.$t('Please write some note!');
                        return false;
                    }

                    try {
                        this.isLoading =true;
                        let {data: {data, message}} = await client().post(`job/applicant/${this.candidate.id}/note`, {
                            'note': this.note,
                            'tags': this.tags
                        });

                        this.error = '';
                        this.resetNote();
                        this.notes.unshift(data);
                        localStorage.removeItem(this.key);
                        this.$toast.success(this.$t(message));

                    } catch (error) {
                        this.error = error.response.data.message;
                    }

                } else {
                    this.$toast.error(this.$t('Sorry you do not have permission for this action.'));
                }
                this.isLoading =false;
            },
            async deleteNote(index, noteId) {
                let confirmMessage ={
                    title : this.$t("Confirmation"),
                    body : this.$t("Are you sure, you want to delete this note?")
                };
                let dialogConfig = {
                    okText: this.$t('yes'),
                    cancelText: this.$t('no'),
                };

                await this.$dialog.confirm(confirmMessage, dialogConfig).then(() => {
                    if (this.company.permission[PERMISSIONS.CANDIDATE_VIEW]) {
                        client().delete(`job/applicant/${this.candidate.id}/note/${noteId}/delete`)
                            .then(({data: {data, message}}) => {
                                this.error = '';
                                this.notes.splice(index, 1);
                                this.$toast.success(this.$t(message));
                            }).catch(({response : { data, status }}) => {
                            this.$toast.error(data.message);
                        });
                    } else {
                        this.$toast.error(this.$t('Sorry you do not have permission for this action.'));
                    }
                });
            },
            draftNotes: _.debounce(function() {
                    if (this.company.permission[PERMISSIONS.CANDIDATE_VIEW]) {
                        try {
                            let {data: {data, message}} =  client().post(`job/applicant/${this.candidate.id}/draft-note`, {
                                'note': this.note,
                                'tags': this.tags
                            });
                        } catch (error) {
                            this.error = error.response.data.message;
                        }

                    }
                }, 1000)
            },
    }
</script>

<style scoped>
    .tag-container {
        margin-top: 5px;
    }
    .tag-name {
        font-size: .7rem;
        font-weight: 500;
        padding: 3px 5px;
        color: #587dfc;
    }

    .custom-link {
        color: #587dfc;
        text-decoration: underline;
        font-size: .8rem;
    }
    .custom-link:hover{
        color: #000000;
        text-decoration: underline;
        cursor: pointer;
    }
    .highlight {
        font-weight: 900;
    }
    .disabled {
        padding: 15px 10px;
        font-size: 0.6rem;
    }

    .note__subtitle {
        font-size: 12px !important;
        display: flex;
        color: #7d8091 !important;
        font-weight: 400 !important;
        width: 100% !important;
        line-height: 16px;
    }

    .note__subtitle i.eicon {
        float: left;
        padding: 1px 2px 1px 1px;
        font-size: 10px;
        line-height: 14px;
    }
</style>
